exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checklist-tsx": () => import("./../../../src/pages/checklist.tsx" /* webpackChunkName: "component---src-pages-checklist-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-opening-times-tsx": () => import("./../../../src/pages/opening-times.tsx" /* webpackChunkName: "component---src-pages-opening-times-tsx" */),
  "component---src-pages-prices-tsx": () => import("./../../../src/pages/prices.tsx" /* webpackChunkName: "component---src-pages-prices-tsx" */)
}

